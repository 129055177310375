import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
// import {
//   cityList,
//   countryList,
//   saveBillingAndShippingAddressAPICall,
//   stateList,
// } from "../../../../../service/apiService";
import {
  cityList,
  countryList,
  saveBillingAndShippingAddressAPICall,
  stateList,
} from "../../../service/apiService";
import "../../../assert/css/modal.css";
import { toast } from "react-toastify";

const NewAdress = (props) => {
  const [pincodeValid, setPincodeValid] = useState(false);
  const [pincodeValidationError, setPincodeValidationError] = useState("");
  const [activeAddressType, setActiveAddressType] = useState("Billing");
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedShippingCountry, setSelectedShippingCountry] = useState(null);
  const [selectedShippingState, setSelectedShippingState] = useState(null);
  const [selectedShippingCity, setSelectedShippingCity] = useState(null);
  const [addressData, setAddressData] = useState({
    billing_name: "",
    billing_email: "",
    billing_phone_no: "",
    billing_address_line_one: "",
    billing_address_line_two: null,
    billing_country: "",
    billing_state: "",
    billing_city: "",
    billing_pincode: "",
    billing_set_default: 1,
    billing_is_active: 1,
    shipping_name: "",
    shipping_email: "",
    shipping_phone_no: "",
    shipping_address_line_one: "",
    shipping_address_line_two: null,
    shipping_country: "",
    shipping_state: "",
    shipping_city: "",
    shipping_pincode: "",
    shipping_set_default: 1,
    shipping_is_active: 1,
  });
  const [errors, setErrors] = useState(false);
  const emailPattern = /^\s*([^\s@]+@[^\s@]+\.[^\s@]+)\s*$/;
  const mobilePattern = /^[0-9]{10}$/;
  const pincodePattern = /^[0-9]*$/;
  const token = localStorage.getItem("token");

  const handleAddressTypeClick = (type) => {
    setActiveAddressType(type);
  };
  const handleShippingCityChange = (selectedOption) => {
    setSelectedShippingCity(selectedOption);
    setAddressData({
      ...addressData,
      shipping_city: selectedOption ? selectedOption.value : "",
    });
  };
  const handleShippingStateChange = (selectedOption) => {
    setSelectedShippingState(selectedOption);
    setSelectedShippingCity(null);
    //setShippingCityOptions([]);
    setAddressData({
      ...addressData,
      shipping_state: selectedOption ? selectedOption.value : "",
      shipping_city: "",
    });
    if (selectedOption) {
      getCityOptions(selectedOption.value, "shipping");
    }
  };
  const handleShippingCountryChange = (selectedOption) => {
    setSelectedShippingCountry(selectedOption);
    setSelectedShippingState(null);
    setSelectedShippingCity(null);
    //  setShippingStateOptions([]);
    //setShippingCityOptions([]);
    setAddressData({
      ...addressData,
      shipping_country: selectedOption ? selectedOption.value : "",
      shipping_state: "",
      shipping_city: "",
    });
    if (selectedOption) {
      getStateOptions(selectedOption.value, "shipping");
    }
  };

  const handleChange = (e, obj) => {
    let name, value;
    if (obj) {
      name = obj.name;
      value = e.value;
    } else {
      name = e.target.name;
      value = e.target.value;
      if (
        name === "billing_phone_no" ||
        name === "shipping_phone_no" ||
        name === "billing_pincode" ||
        name === "shipping_pincode"
      ) {
        value = e.target.value.replace(/\D/g, "");
      }
    }

    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  const getCountryOptions = async () => {
    try {
      const response = await countryList();
      if (response.status_code === 200) {
        setCountryOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getStateOptions = async (countryId) => {
    try {
      const response = await stateList(countryId);
      if (response.status_code === 200) {
        setStateOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCityOptions = async (stateId) => {
    try {
      const response = await cityList(stateId);
      if (response.status_code === 200) {
        setCityOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCountryOptions();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedState(null);
    setSelectedCity(null);
    setStateOptions([]);
    setCityOptions([]);
    setAddressData({
      ...addressData,
      billing_country: selectedOption ? selectedOption.value : "",
      billing_state: "",
      billing_city: "",
    });
    if (selectedOption) {
      getStateOptions(selectedOption.value);
    }
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setSelectedCity(null);
    setCityOptions([]);
    setAddressData({
      ...addressData,
      billing_state: selectedOption ? selectedOption.value : "",
      billing_city: "",
    });
    if (selectedOption) {
      getCityOptions(selectedOption.value);
    }
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    setAddressData({
      ...addressData,
      billing_city: selectedOption ? selectedOption.value : "",
    });
  };

  const validateForm = () => {
    let isValid = true;

    const requiredFields = [
      "billing_name",
      "billing_email",
      "billing_phone_no",
      "billing_address_line_one",
      "billing_country",
      "billing_state",
      "billing_city",
      "billing_pincode",
      "shipping_name",
      "shipping_email",
      "shipping_phone_no",
      "shipping_address_line_one",
      "shipping_country",
      "shipping_state",
      "shipping_city",
      "shipping_pincode",
    ];

    requiredFields.forEach((field) => {
      if (addressData[field] === "") {
        setErrors(true);
        isValid = false;
      }
    });

    // Email validation
    if (
      addressData["billing_email"] &&
      !emailPattern.test(addressData["billing_email"])
    ) {
      setErrors(true);
      isValid = false;
    }
    if (
      addressData["shipping_email"] &&
      !emailPattern.test(addressData["shipping_email"])
    ) {
      setErrors(true);
      isValid = false;
    }

    // Mobile number validation
    if (
      addressData["billing_phone_no"] &&
      !mobilePattern.test(addressData["billing_phone_no"])
    ) {
      setErrors(true);
      isValid = false;
    }
    if (
      addressData["shipping_phone_no"] &&
      !mobilePattern.test(addressData["shipping_phone_no"])
    ) {
      setErrors(true);
      isValid = false;
    }

    // Pin code validation (must be numeric)
    if (
      addressData["billing_pincode"] &&
      !pincodePattern.test(addressData["billing_pincode"])
    ) {
      setErrors(true);
      isValid = false;
    }
    if (
      addressData["shipping_pincode"] &&
      !pincodePattern.test(addressData["shipping_pincode"])
    ) {
      setErrors(true);
      isValid = false;
    }

    return isValid;
  };

  {
    console.log(addressData, "adreesss");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = validateForm();
    if (!isValid) {
      return;
    }
    try {
      const checkPinCode = Number(addressData.billing_pincode);
      const validatePinCodeUrl = `https://api.postalpincode.in/pincode/${checkPinCode}`;

      const response = await fetch(validatePinCodeUrl);
      const data = await response.json();

      if (data[0].Status === "Success") {
        setPincodeValid(true);
        setPincodeValidationError("");
      } else {
        setPincodeValid(false);
        setPincodeValidationError("Given Pin Code is InValid!!!");
        return;
      }
    } catch (error) {
      console.error("Error validating pincode:", error);
      setPincodeValid(false);
      setPincodeValidationError("Error validating pincode.");
      return;
    }

    let payload = {
      ...addressData,
      billing_phone_no: Number(addressData.billing_phone_no),
      billing_pincode: Number(addressData.billing_pincode),
      shipping_phone_no: Number(addressData.shipping_phone_no),
      shipping_pincode: Number(addressData.shipping_pincode),
    };

    try {
      const response = await saveBillingAndShippingAddressAPICall(payload);
      if (response.status_code == 200) {
        toast.success("Billing and shipping address added successfully!");
        closeModel();
        document.getElementById("closeAddressFormButton").click();
        props.getShippingAddress();
        props.getBillingAddress();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const billingAndShippingAddressSame = (e) => {
    if (e.target.checked) {
      setAddressData((prevState) => ({
        ...prevState,
        shipping_name: prevState.billing_name,
        shipping_email: prevState.billing_email,
        shipping_phone_no: prevState.billing_phone_no,
        shipping_address_line_one: prevState.billing_address_line_one,
        shipping_address_line_two: prevState.billing_address_line_two,
        shipping_country: prevState.billing_country,
        shipping_state: prevState.billing_state,
        shipping_city: prevState.billing_city,
        shipping_pincode: prevState.billing_pincode,
      }));
    } else {
      setAddressData((prevState) => ({
        ...prevState,
        shipping_name: "",
        shipping_email: "",
        shipping_phone_no: "",
        shipping_address_line_one: "",
        shipping_address_line_two: null,
        shipping_country: "",
        shipping_state: "",
        shipping_city: "",
        shipping_pincode: "",
      }));
    }
  };

  const closeModel = () => {
    setActiveAddressType("Billing");
    setErrors(false);
    setAddressData({
      billing_name: "",
      billing_email: "",
      billing_phone_no: "",
      billing_address_line_one: "",
      billing_address_line_two: null,
      billing_country: "",
      billing_state: "",
      billing_city: "",
      billing_pincode: "",
      billing_set_default: 1,
      billing_is_active: 0,
      shipping_name: "",
      shipping_email: "",
      shipping_phone_no: "",
      shipping_address_line_one: "",
      shipping_address_line_two: null,
      shipping_country: "",
      shipping_state: "",
      shipping_city: "",
      shipping_pincode: "",
      shipping_set_default: 1,
      shipping_is_active: 0,
    });
  };

  return (
    <div
      className="modal fade modal_addr_change"
      id="addr_change"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content add_new_add_window">
          <div className="modal-header ">
            <h1 className="modal-title " id="staticBackdropLabel">
              {" "}
              ADD NEW ADDRESS
            </h1>
            <button
              type="button"
              id="closeAddressFormButton"
              className="btn-close sel_del_addr_btn "
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModel}
            />
          </div>
          <form>
            <div className="modal-body">
              {activeAddressType === "Billing" ? (
                <div>
                  <div className="contact_det row">
                    <div className="col-md-12">
                      <h6 className="a_n_a_w_title">CONTACT DETAILS</h6>
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="billing_name">Name*</label>
                      <input
                        class="form-control"
                        type="text"
                        name="billing_name"
                        id="billing_name"
                        value={addressData.billing_name}
                        onChange={handleChange}
                        required
                      />
                      {console.log(errors, "errors12")}
                      {errors && addressData.billing_name === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter name !
                        </div>
                      ) : null}
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="billing_phone_no">Mobile No*</label>
                      <input
                        type="text"
                        name="billing_phone_no"
                        id="billing_phone_no"
                        value={addressData.billing_phone_no}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.billing_phone_no === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter mobile number !
                        </div>
                      ) : null}
                      {errors &&
                      addressData.billing_phone_no !== "" &&
                      !mobilePattern.test(addressData["billing_phone_no"]) ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter valid mobile number !
                        </div>
                      ) : null}
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="billing_email">Email*</label>
                      <input
                        type="text"
                        name="billing_email"
                        id="billing_email"
                        value={addressData.billing_email}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.billing_email === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter email address !
                        </div>
                      ) : null}
                      {errors &&
                      addressData.billing_email !== "" &&
                      !emailPattern.test(addressData["billing_email"]) ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Email address is invalid !
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="addr_det row">
                    <div className="col-md-12">
                      <h6 className="a_n_a_w_title">Billing Address</h6>
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="billing_address_line_one">
                        Address (House No, Building, Street, Area)*
                      </label>
                      <input
                        type="text"
                        name="billing_address_line_one"
                        id="billing_address_line_one"
                        value={addressData.billing_address_line_one}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.billing_address_line_one === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter address !
                        </div>
                      ) : null}
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="billing_address_line_two">
                        Locality / Town*
                      </label>
                      <input
                        type="text"
                        name="billing_address_line_two"
                        id="billing_address_line_two"
                        value={addressData.billing_address_line_two}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input_par col-md-6">
                      <Select
                        options={countryOptions.map((country) => ({
                          label: country.country_name,
                          value: country.country_id,
                        }))}
                        name="billing_country"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        placeholder="Select a country"
                      />
                      {errors && addressData.billing_country === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please select a country!
                        </div>
                      )}
                    </div>
                    <div className="input_par col-md-6">
                      <Select
                        options={stateOptions.map((state) => ({
                          label: state.state_name,
                          value: state.state_id,
                        }))}
                        name="billing_state"
                        value={selectedState}
                        onChange={handleStateChange}
                        placeholder="Select a state"
                        isDisabled={!selectedCountry}
                      />
                      {errors && addressData.billing_state === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please select a state!
                        </div>
                      )}
                    </div>
                    <div className="input_par col-md-6">
                      <Select
                        options={cityOptions.map((city) => ({
                          label: city.city_name,
                          value: city.city_id,
                        }))}
                        name="billing_city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        placeholder="Select a city"
                        isDisabled={!selectedState}
                      />
                      {errors && addressData.billing_city === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please select a city!
                        </div>
                      )}
                    </div>
                    <div style={{ height: "50px" }} className="input_par col-md-6">
                      <label htmlFor="billing_pincode">Pin Code*</label>
                      <input
                        type="text"
                        name="billing_pincode"
                        id="billing_pincode"
                        value={addressData.billing_pincode}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.billing_pincode === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter pincode!
                        </div>
                      )}

                      {errors &&
                        addressData.billing_pincode !== "" &&
                        !pincodePattern.test(
                          addressData["billing_pincode"]
                        ) && (
                          <div
                            className="text-danger"
                            style={{ fontSize: "14px" }}
                          >
                            Please enter a valid pincode!
                          </div>
                        )}
                      {!pincodeValid && pincodeValidationError && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          {pincodeValidationError}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="contact_det row">
                    <div className="col-md-12">
                      <h6 className="a_n_a_w_title">CONTACT DETAILS</h6>
                    </div>

                    <div className="input_par col-md-12">
                      <label htmlFor="shipping_name">Name*</label>
                      <input
                        type="text"
                        name="shipping_name"
                        id="shipping_name"
                        value={addressData.shipping_name}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.shipping_name === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter name !
                        </div>
                      ) : null}
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="shipping_phone_no">Mobile No*</label>
                      <input
                        type="text"
                        name="shipping_phone_no"
                        id="shipping_phone_no"
                        value={addressData.shipping_phone_no}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.shipping_phone_no === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter mobile no !
                        </div>
                      ) : null}
                      {errors &&
                      addressData.shipping_phone_no !== "" &&
                      !mobilePattern.test(addressData["shipping_phone_no"]) ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter valid mobile number !
                        </div>
                      ) : null}
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="shipping_email">Email*</label>
                      <input
                        type="text"
                        name="shipping_email"
                        id="shipping_email"
                        value={addressData.shipping_email}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.shipping_email === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter email address !
                        </div>
                      ) : null}
                      {errors &&
                      addressData.shipping_email !== "" &&
                      !emailPattern.test(addressData["shipping_email"]) ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Email address is invalid !
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="addr_det row">
                    <div className="col-md-12">
                      <h6 className="a_n_a_w_title">Shipping Address</h6>
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="shipping_address_line_one">
                        Address (House No, Building, Street, Area)*
                      </label>
                      <input
                        type="text"
                        name="shipping_address_line_one"
                        id="shipping_address_line_one"
                        value={addressData.shipping_address_line_one}
                        onChange={handleChange}
                        required
                      />
                      {errors &&
                      addressData.shipping_address_line_one === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter address !
                        </div>
                      ) : null}
                    </div>
                    <div className="input_par col-md-12">
                      <label htmlFor="shipping_address_line_two">
                        Locality / Town*
                      </label>
                      <input
                        type="text"
                        name="shipping_address_line_two"
                        id="shipping_address_line_two"
                        value={addressData.shipping_address_line_two || ""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input_par col-md-6">
                      <Select
                        options={countryOptions.map((country) => ({
                          label: country.country_name,
                          value: country.country_id,
                        }))}
                        name="shipping_country"
                        value={selectedShippingCountry}
                        onChange={handleShippingCountryChange}
                        placeholder="Select a country"
                      />
                      {errors && addressData.shipping_country === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please select a country!
                        </div>
                      )}
                    </div>
                    <div className="input_par col-md-6">
                      <Select
                        options={stateOptions.map((state) => ({
                          label: state.state_name,
                          value: state.state_id,
                        }))}
                        name="shipping_state"
                        value={selectedShippingState}
                        onChange={handleShippingStateChange}
                        placeholder="Select a state"
                        isDisabled={!selectedShippingCountry}
                      />
                      {errors && addressData.shipping_state === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please select a state!
                        </div>
                      )}
                    </div>
                    <div className="input_par col-md-6">
                      <Select
                        options={cityOptions.map((city) => ({
                          label: city.city_name,
                          value: city.city_id,
                        }))}
                        name="shipping_city"
                        value={selectedShippingCity}
                        onChange={handleShippingCityChange}
                        placeholder="Select a city"
                        isDisabled={!selectedShippingState}
                      />
                      {errors && addressData.shipping_city === "" && (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please select a city!
                        </div>
                      )}
                    </div>
                    <div className="input_par col-md-6">
                      <label htmlFor="shipping_pincode">Pin Code*</label>
                      <input
                        type="text"
                        name="shipping_pincode"
                        id="shipping_pincode"
                        value={addressData.shipping_pincode}
                        onChange={handleChange}
                        required
                      />
                      {errors && addressData.shipping_pincode === "" ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter pincode !
                        </div>
                      ) : null}
                      {errors &&
                      addressData.shipping_pincode !== "" &&
                      !pincodePattern.test(addressData["shipping_pincode"]) ? (
                        <div
                          className="text-danger"
                          style={{ fontSize: "14px" }}
                        >
                          Please enter valid pincode !
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}

              <div className="row home_work_addr">
                <div className="col-md-12">
                  <h6 className="a_n_a_w_title">SAVE ADDRESS AS</h6>
                </div>
                <div className="col-md-12">
                  <div className="home_work_addr_btn">
                    <Link
                      to="#"
                      className={
                        activeAddressType === "Billing" ? "active" : ""
                      }
                      onClick={() => handleAddressTypeClick("Billing")}
                    >
                      Billing
                    </Link>
                    <Link
                      to="#"
                      className={
                        activeAddressType === "Shipping" ? "active" : ""
                      }
                      onClick={() => handleAddressTypeClick("Shipping")}
                    >
                      Shipping
                    </Link>
                  </div>
                  <div className=" mt-3 d-flex align-items-center form-check form-check-inline ">
                    <input
                      id="default"
                      name="default"
                      value="yes"
                      onChange={billingAndShippingAddressSame}
                      className="form-check-input me-2  "
                      type="checkbox"
                      style={{ width: "15px", height: "15px" }}
                    />
                    <h4
                      htmlFor="default m-0 ms-2 fs-2 fw-bold"
                      style={{ margin: "0" }}
                    >
                      Make Billing and shipping the same
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center align-items-center">
              <button
                className="btn btn-primary px-5 py-3 mt-3 mb-3 "
                onClick={(e) => handleSubmit(e)}
              >
                ADD ADDRESS
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewAdress;
